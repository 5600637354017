@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Karla:wght@200&family=Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important; /* for Firefox */
  line-height: 1.5;
}

body::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 200%;
  margin-top: 0;
}

h2 {
  margin: 0;
}

p,
li {
  font-size: 125%;
  margin: 0;
  line-height: 1.5;
}
